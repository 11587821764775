/* eslint-env jest */
import axios from 'axios';
import actions from './actions';

jest.mock('axios');

describe('actions', () => {
  describe.skip('fetch', () => {
    it('should call cgi and commit', async () => {
      const spy = jest.fn();
      axios.post.mockResolvedValue({
        data: 'test',
      });
      await actions.fetch({
        commit: spy,
        rootGetters: {},
      });
      expect(axios.post.mock.calls[0][0]).toEqual('/fcgi-bin/operator/gconf.query');
      expect(axios.post.mock.calls[0][1].startsWith('path=/system/software/raphael/web/scene')).toBe(true);
      expect(spy).toBeCalledWith('setScene', 'test');
    });
  });

  describe('goPreviousPage', () => {
    it('should commit previous page if not out of range', () => {
      const spy = jest.fn();
      const state = {
        activePageIndex: 1,
      };
      actions.goPreviousPage({
        state,
        commit: spy,
      });
      expect(spy).toBeCalledWith('goPreviousPage');
    });

    it('should not commit previous page if out of range', () => {
      const spy = jest.fn();
      const state = {
        activePageIndex: 0,
      };
      actions.goPreviousPage({
        state,
        commit: spy,
      });
      expect(spy).not.toBeCalledWith('goPreviousPage');
    });
  });

  describe('goNextPage', () => {
    it('should commit previous page if not out of range', () => {
      const spy = jest.fn();
      const state = {
        activePageIndex: 0,
      };
      const getters = {
        pageCount: 2,
      };
      actions.goNextPage({
        state,
        commit: spy,
        getters
      });
      expect(spy).toBeCalledWith('goNextPage');
    });

    it('should not commit previous page if out of range', () => {
      const spy = jest.fn();
      const state = {
        activePageIndex: 1,
      };
      const getters = {
        pageCount: 2,
      };
      actions.goNextPage({
        state,
        commit: spy,
        getters
      });
      expect(spy).not.toBeCalledWith('goNextPage');
    });
  });
});
